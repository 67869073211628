import React from 'react'
import plan from '../../../images/plan.png'
import write from '../../../images/write.svg'

import "./Service.css"


const Service = () => {
  return (
    <section>
      <div className='parent-service'>
        <div className='sub-service'>
          <div className='my-2 text-center'>
            <div className='text-center mb-2 pry-bold-text'>
              <h1 class='h1-responsive text-center my-2 '>Our Services</h1>
            </div>
          </div>
          <div className=''>
            <div className='row align-items-start justify-content-between py-4 mx-0 px-0 w-100 direction'>
              <div className='col-lg-6 px-0'>
                <h3 className='text-start mb-4 yellow'>
                  Consulting and Constructing services
                </h3>
                <p className='pb-4 lh-lg'>
                  The design division of Emniks Engineering Ltd. has extensive
                  expertise in all kinds of design and drafting of various civil
                  engineering structures.
                </p>
                <p className='lh-lg'>
                  {' '}
                  We have a particularly strong presence in structural
                  detailing, horizontal, vertical and cross-sectional design of
                  roads, water structures and reproduction of architectural
                  drawings.
                </p>
                <div className='py-1 pry br-md mt-5'></div>
              </div>
              <div className='col-lg-4  p-0 mb-md-4 fup dot'>
                <img
                  src={plan}
                  alt=''
                  width='100%'
                  className='image fup  mb-md-4 m-0 dots text-end'
                />
              </div>
            </div>
            <div className='row align-items-start justify-content-between py-4 mx-0 px-0 w-100  mt-5'>
              <div className='col-lg-4 text-start p-0 m-0 fup dot'>
                <img
                  src={write}
                  alt=''
                  width={'100%'}
                  className='image mb-md-4 m-0 fup dots'
                />
              </div>
              <div className='col-lg-6 line px-0'>
                <h3 className='mb-4 yellow'>CONSTRUCTION</h3>
                <p className='pb-4 lh-lg'>
                  Whatever your demand, Emniks Engineering Limited is well
                  positioned to meet your need under the most convenient
                  condition. The firm undertakes construction of civil
                  engineering projects of any magnitude with dedicated and
                  hardworking team of professionals, ever ready to beat project
                  deadlines.
                </p>
                <p className='lh-lg'>
                  {' '}
                  We provide high and quality building/ Civil works. To us
                  EMNIK, we ensure details and specifications to produce high
                  standard of construction.
                </p>
                <div className='py-1 pry br-md mt-5'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Service
