import React from 'react'

import "./Board.css"

const Board = () => {
  const aims = [
    {
      i: 1,
      im: <BoardIcon />,
      name: 'Felicia Adenike Akinrinde',
      post: 'Managing Director',
    },
    {
      i: 2,
      im: <BoardIcon />,
      name: 'Engr. Noah Olukayode Oyeleke (MNSE)',
      post: 'Project Director',
    },
    {
      i: 3,
      im: <BoardIcon />,
      name: 'Emmanuel Oyelakin Akinrinde',
      post: 'Chairman',
    },
    {
      i: 4,
      im: <BoardIcon />,
      name: 'Chief(Mrs) Modupe Babalola',
      post: 'Director',
    },
    {
      i: 5,
      im: <BoardIcon />,
      name: 'HRH Oba Olumuyiwa Ogunbekun (Onilekki of Lekki - Lagos) ',
      post: 'Director',
    },
  ]
  return (
    <div className='parent-board'>
      <div className='sub-board'>
        <div className='more-title w-100'>
          <h4 className='h4-responsive text-lg-start text-center my-4 yellow'>
            <b>Board of directors</b>
          </h4>
        </div>
        <div className='row justify-content-center p-0 m-0'>
          {aims.map((aim, i) => {
            const { im, name, post } = aim
            return (
              <div className='col-md-6 mb-2 col-lg-4' key={i}>
                <div className='card shadow-none h-100 first py-2 px-3 br-md d-flex justify-content-center align-items-center'>
                  <div className='mb-3 board-img '>{im}</div>
                  {/* <h5 className=" my-4">
                                Humanity and Human Rights Alertness
                            </h5> */}
                  <h6 className='text-center '>
                    <b>{name}</b>
                  </h6>
                  <p className='text-center yellow'>
                   {post}
                  </p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Board

function BoardIcon() {
  return (
    <svg
      width='38'
      height='38'
      viewBox='0 0 38 38'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M25.7786 14.0002C25.7786 15.7683 25.0763 17.464 23.826 18.7142C22.5758 19.9644 20.8801 20.6668 19.112 20.6668C17.3439 20.6668 15.6482 19.9644 14.3979 18.7142C13.1477 17.464 12.4453 15.7683 12.4453 14.0002C12.4453 12.2321 13.1477 10.5364 14.3979 9.28612C15.6482 8.03587 17.3439 7.3335 19.112 7.3335C20.8801 7.3335 22.5758 8.03587 23.826 9.28612C25.0763 10.5364 25.7786 12.2321 25.7786 14.0002ZM22.4453 14.0002C22.4453 14.8842 22.0941 15.7321 21.469 16.3572C20.8439 16.9823 19.996 17.3335 19.112 17.3335C18.2279 17.3335 17.3801 16.9823 16.755 16.3572C16.1298 15.7321 15.7786 14.8842 15.7786 14.0002C15.7786 13.1161 16.1298 12.2683 16.755 11.6431C17.3801 11.018 18.2279 10.6668 19.112 10.6668C19.996 10.6668 20.8439 11.018 21.469 11.6431C22.0941 12.2683 22.4453 13.1161 22.4453 14.0002Z'
        fill='#3366B0'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M19.1126 0.666748C8.98763 0.666748 0.779297 8.87508 0.779297 19.0001C0.779297 29.1251 8.98763 37.3334 19.1126 37.3334C29.2376 37.3334 37.446 29.1251 37.446 19.0001C37.446 8.87508 29.2376 0.666748 19.1126 0.666748ZM4.11263 19.0001C4.11263 22.4834 5.30096 25.6901 7.29263 28.2367C8.69135 26.3999 10.4958 24.9113 12.565 23.8873C14.6342 22.8632 16.9122 22.3314 19.221 22.3334C21.4998 22.3313 23.7492 22.8493 25.7975 23.848C27.8459 24.8468 29.6392 26.3 31.041 28.0967C32.485 26.2027 33.4574 23.9921 33.8775 21.6477C34.2975 19.3034 34.1533 16.8926 33.4568 14.6151C32.7602 12.3375 31.5313 10.2585 29.8718 8.55014C28.2122 6.84179 26.1697 5.55318 23.9133 4.79093C21.6568 4.02868 19.2513 3.8147 16.8957 4.1667C14.5402 4.5187 12.3023 5.42657 10.3672 6.81517C8.43221 8.20377 6.85565 10.0332 5.76801 12.1521C4.68038 14.2709 4.11293 16.6184 4.11263 19.0001V19.0001ZM19.1126 34.0001C15.6692 34.0052 12.3297 32.8207 9.6593 30.6467C10.7342 29.108 12.1649 27.8516 13.8296 26.9846C15.4944 26.1175 17.3439 25.6654 19.221 25.6667C21.0746 25.6653 22.9018 26.106 24.5508 26.9525C26.1999 27.7989 27.6232 29.0265 28.7026 30.5334C26.0115 32.7779 22.6169 34.005 19.1126 34.0001V34.0001Z'
        fill='#3366B0'
      />
    </svg>
  )
}