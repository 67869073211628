import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Navbar from '../Navbar/Navbar'
import logo from '../../images/Logo.svg'



// Gallery images imports 
import img1 from '../../images/img1.png'
import img2 from '../../images/img2.png'
import img3 from '../../images/img3.png'
import img4 from '../../images/img4.png'
import img5 from '../../images/img5.png'
import img6 from '../../images/img6.png'
import img7 from '../../images/img7.png'
import img8 from '../../images/img8.png'
import img9 from '../../images/img9.png'
import img10 from '../../images/img10.png'
import img11 from '../../images/img11.png'
import img12 from '../../images/img12.png'
import img13 from '../../images/img13.png'
import img14 from '../../images/img14.png'
import img15 from '../../images/img15.png'
import img16 from '../../images/img16.png'
import img17 from '../../images/img17.png'
import img18 from '../../images/img18.png'
import img19 from '../../images/img19.png'
import img20 from '../../images/img20.png'
import img21 from '../../images/img21.png'
import img22 from '../../images/img22.png'
import img23 from '../../images/img23.png'
import img24 from '../../images/img24.png'
import img25 from '../../images/img25.png'
import img26 from '../../images/img26.png'
import img27 from '../../images/img27.png'
import img28 from '../../images/img28.png'
import img29 from '../../images/img29.png'
import img30 from '../../images/img30.png'
import img31 from '../../images/img31.png'
import img32 from '../../images/img32.png'
import img33 from '../../images/img33.png'
import img34 from '../../images/img34.png'
import img35 from '../../images/img35.png'
import img36 from '../../images/img36.png'
import img37 from '../../images/img37.png'
import img38 from '../../images/img38.png'
import img39 from '../../images/img39.png'
import img40 from '../../images/img40.png'
import img41 from '../../images/img41.png'
import img42 from '../../images/img42.png'
import img43 from '../../images/img43.png'
import img44 from '../../images/img44.png'
import img45 from '../../images/img45.png'
import img46 from '../../images/img46.png'
import img47 from '../../images/img47.png'

const Gallery = () => {
  const routepath = useLocation()

  const [active, setActive] = useState('top')

  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])

  const aims = [

    {
      im: img1,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img2,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img3,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img4,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img5,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img6,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img7,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img8,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img9,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img10,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img11,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img12,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img13,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img14,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img15,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img16,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img17,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img18,
      filter: 'middle',
      content: 'Image Caption',
    },
    {
      im: img19,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img20,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img21,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img22,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img23,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img24,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img25,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img26,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img27,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img28,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img29,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img30,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img31,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img32,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img33,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img34,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img35,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img36,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img37,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img38,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img39,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img40,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img41,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img42,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img43,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img44,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img45,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img46,
      filter: 'bottom',
      content: 'Image Caption',
    },
    {
      im: img47,
      filter: 'bottom',
      content: 'Image Caption',
    },
  ]

  return (
    <section>
      <Navbar logo={logo} btncolor='#ffffff' btncolors='#3366B0' />
      <div className='parent-more ' style={{ marginTop: '5vh' }}>
        <div className='sub-more'>
          <div className='more-title'>
            <h1 className='h1-responsive text-center my-4 '>Gallery</h1>
            <div className="tabs d-flex align-items-center">
              <div onClick={() => setActive('top')} className={`tabInner ${active === 'top' ? 'red topradius' : ''}`}>
                <p>All</p>
              </div>
              <div onClick={() => setActive('middle')} className={`tabInner middle ${active === 'middle' ? 'red' : ''}`}>
                <p>Completed</p>
                <p>Projects</p>
              </div>
              <div onClick={() => setActive('bottom')} className={`tabInner ${active === 'bottom' ? 'red bottomradius' : ''}`}>
                <p>Uncompleted</p>
                <p>Projects</p>
              </div>
            </div>
          </div>
          <div className='row justify-content-center p-0 m-0'>
            {aims.filter((item) =>
              item.filter.includes(active === 'top' ? '' : active)
            ).map((aim, i) => {
              const { im, content } = aim
              return (
                <div className='col-md-6 mb-3 col-lg-4  animate__animated animate__fadeIn' key={i}>
                  <div className='card shadow-none h-100 first py-3 px-3 br-md d-flex align-items-start '>
                    <div className='mb-3 animate__animated animate__fadeIn'>
                      <img src={im} alt='' width={'100%'} />
                    </div>
                    {/* <h5 className=" my-4">
                                Humanity and Human Rights Alertness
                            </h5> */}
                    <p className='text-center'>{content}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Gallery
