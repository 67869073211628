import React, { useState, useEffect } from 'react'
import { NavLink, Link } from 'react-router-dom'
import './Navbar.css'
const Navbar = ({ logo, colors, btncolors, btncolor }) => {
  const [toggle, setToggle] = useState(false)

  // to show background or not
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    // Button is displayed after scrolling for 500 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 40) {
        setIsVisible(true)
      } else {
        setIsVisible(false)
      }
    }
    window.addEventListener('scroll', toggleVisibility)
    return () => window.removeEventListener('scroll', toggleVisibility)
  }, []) 
    // to show background or not



  let activeStyle = {
    backgroundColor: 'rgba(51, 102, 176, 0.3)',
    color: '#fff',
    borderBottom: '2px solid var(--pry-blue-color)',
    transition: '1s ease-in-out',
    fontWeight: '900 !important',
  }
  let activeStyles = {
    color: 'var(--pry-blue-color)',
    transition: '1s ease-in-out',
    fontWeight: '900 !important',
  }
  //  const [isActive, setIsActive] = useState("false")

  //  const handleToggle = () => {
  //    setIsActive(!isActive)
  //  }

  const openNav = () => {
    setToggle(true)
    document.getElementById('mySidebar').style.width = '100%'
    // document.getElementById('times').style.backgroundColor = '#000000'
    document.getElementById('times').style.transition = '1s ease'
  }
  const closeNav = () => {
    setToggle(false)
    document.getElementById('mySidebar').style.width = '0px'
    // document.getElementById('times').style.backgroundColor = '#ffffff'

  }

  return (
    <div>
      <nav
        className={`navbar navbar-expand-lg navbar-light fixed-top ${
          isVisible ? 'nav-scrolled' : ''
        }`}
        id='times'
        style={{ color: colors }}
      >
        <div className='container app__navbar-logo'>
          <Link className='navbar-brand' to='/'>
            <img src={logo} alt='Emniks Engineering logo' />
          </Link>
          {toggle ? (
            // href='javascript:void(0)'
            // className='closebtn'
            // onClick={closeNav}
            <i
              className='fas fa-times nav-btn'
              onClick={closeNav}
            ></i>
          ) : (
            <i
              className='fas fa-bars nav-btn navbar-toggler'
              onClick={openNav}
            ></i>
          )}
          <div
            className='collapse navbar-collapse malt'
            id='navbarSupportedContent'
          >
            <ul className='navbar-nav text-center animated fadeInUp mx-auto'>
              <li className='nav-item'>
                <NavLink
                  className='nav-link'
                  to='/'
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                  end
                >
                  Home
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className='nav-link'
                  to='/company'
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Our Company
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className='nav-link'
                  to='/services'
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Our Services
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className='nav-link'
                  to='/projects'
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Projects
                </NavLink>
              </li>
              <li className='nav-item'>
                <NavLink
                  className='nav-link'
                  to='/gallery'
                  style={({ isActive }) => (isActive ? activeStyle : undefined)}
                >
                  Gallery
                </NavLink>
              </li>
            </ul>
            <ul className='navbar-nav animated text-center'>
              <li className=' btn-contact'>
                <NavLink to='/contact-us'>
                  <button
                    type='btn'
                    className='btn btc shadow-none light-text br-lg'
                    style={{ color: btncolor, backgroundColor: btncolors }}
                  >
                    Contact US
                  </button>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div id='mySidebar' className='sidebar'>
        <NavLink
          to='/'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyles : undefined)}
          end
        >
          Home
        </NavLink>{' '}
        <br />
        <NavLink
          to='/company'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyles : undefined)}
        >
          Our Company
        </NavLink>
        <br />
        <NavLink
          to='/services'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyles : undefined)}
        >
          Our Services
        </NavLink>
        <br />
        <NavLink
          to='/projects'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyles : undefined)}
        >
          Projects
        </NavLink>{' '}
        <br />
        <NavLink
          to='/gallery'
          onClick={closeNav}
          style={({ isActive }) => (isActive ? activeStyles : undefined)}
        >
          Gallery
        </NavLink>
        <br />
        <NavLink to='/contact-us' onClick={closeNav}>
          <button
            type='btn'
            className='btn btc shadow-none light-text br-lg'
            style={{ color: '#fff' }}
          >
            Contact US
          </button>
        </NavLink>
        <br />
      </div>
    </div>
  )
}

export default Navbar
