import { Link } from 'react-router-dom'
import Navbar from '../Navbar/Navbar';
import "./PageNotFound.css"

import logo from '../../images/Logo.svg'

const PageNotFound = () => {
    return (
        <>
        <Navbar logo={logo} btncolor="#ffffff" btncolors="#3366B0" />
            <main>
                <div className="container">
                    <div className="not-found text-center pry-bold-text">
                        <div className="">
                           <h1 className="" >
                            404
                           </h1>
                            <h4 className="">
                                PAGE REQUESTED NOT FOUND
                            </h4>
                            <Link to="/" className=''>
                                <button className="pry-bold shadow-none btn bold-text mt-4 btc"> 
                                <h6 className="m-0">
                                Home
                                </h6>
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
}

export default PageNotFound;