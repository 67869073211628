import React from 'react'
import { Link} from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'

import './Header.css'

const Header = () => {



  return (
    <section>
      <div className='par-header'>
        <div className='sub-header'>
          <h1>EMNIKS ENGINEERING</h1>
          <h4>NIG. LIMITED</h4>
          <div className='row mx-0 w-lg-75 w-100 top-row'>
            <div className='col-4'>
              <p>Project and Estate Management</p>
            </div>
            <div className='col-1'>
              <div className='headcircle'></div>
            </div>
            <div className='col-3'>
              <p>Civil Constructions</p>
            </div>
            <div className='col-1'>
              <div className='headcircle'></div>
            </div>
            <div className='col-3'>
              <p>Engineering Constructions</p>
            </div>
          </div>
          <Link to='/company'>
            <button type='submit' className='btn my-4 bth'>
              <b>
                Learn more <i class='bi bi-arrow-right text-bold'></i>
              </b>
            </button>
          </Link>
        </div>
        <div className='sub2-header'>
          <HashLink
                to={'/#move'}
                scroll={(el) =>
                  el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                }
              >
          <div className='down'>
            <ArrowDown />
          </div>
          </HashLink >
        </div>
      </div>
    </section>
  )
}

export default Header

function ArrowDown(){
  return (
    <svg
      width='20'
      height='33'
      viewBox='0 0 56 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.88187 0.684006L0.980469 5.5854L28.3951 33L55.8097 5.5854L50.9083 0.684006L28.3951 23.1972L5.88187 0.684006Z'
        fill='#3366B0'
      />
    </svg>
  );
}