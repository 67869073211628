import React, { useEffect } from 'react'
import Home from './Home/Home'
import Who from './Who/Who'
import Vision from './Vision/Vision'
import More from './More/More'
import Testimonies from './Testimonies/Testimonies'
import Header from './Header/Header'

import { useLocation} from 'react-router-dom'
import Navbar from '../Navbar/Navbar'

import logo from '../../images/Logo.svg'

const Homes = () => {
  const routepath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])


  return (
    <div>
      {/* this is everything holding the home page accordingly */}
      <Navbar logo={logo} btncolors="#ffffff" btncolor="#3366B0"/>
      <Header/>
      <Home/>
      <Who />
      <Vision />
      <More />
      <Testimonies />
    </div>
  )
}

export default Homes
