import React from 'react'
import drawing from '../../../images/drawing.png'
import building from '../../../images/building.png'
import arc from '../../../images/arc.png'
 import "./Who.css"

const Who = () => {
  return (
    <section>
      <div className='parent-who'>
        <div className='sub-who'>
          <div className='who-title mt-5'>
            <h1 className='h1-responsive text-center mb-4'>
              <b>Who we are</b>
            </h1>
            <p className='text-center w-responsive mx-auto mb-5'>
              We are team of well equipped professional Engineers armed with the
              latest and modern technology to meet the demand of the
              construction industry.
            </p>
          </div>
          <div className='who-img row mb-lg-5 mb-md-3'>
            <div className='col-4'>
              <img src={drawing} alt='drawing' width={'100%'} />
            </div>
            <div className='col-4'>
              <img src={building} alt='building' width={'100%'} />
            </div>
            <div className='col-4'>
              <img src={arc} alt='arc' width={'100%'} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Who
