import React from 'react'
// import certificate from "../../../images/certificate.png"

import "./Certificate.css"

const Certificate = () => {
  return (
    <section>
      <div className='certificate d-flex justify-content-center align-items-center w-100'>
        <div
          className='sub-certificate d-flex justify-content-center align-items-center flex-column'
          style={{ width: '90%' }}
        >
          <div className='my-3 text-start w-100'>
            <div className='text-start mb-0 pry-bold-text mt-5'>
              <h3 class='h1-responsive text-start my-0 yellow'>
                CERTIFICATION & REGISTRATIONS
              </h3>
            </div>
          </div>
          <div className='container-fluid d-flex justify-content-center align-items-center p-0 m-0'>
            <div className='row first align-items-start justify-content-between py-3 px-0 m-0 w-100'>
              <div className='col-lg-12 p-0'>
                <p className='pb-5'>
                  Emniks Engineering Limited started operation in January, 2008
                  and was incorporated as a <br /> Limited Liability company in
                  2008.
                </p>
                <p className='pb-5 lh-lg'>
                  Our Registration No. is RC 775366 of 24th September 2008.{' '}
                  <br /> The Company's registered office is: 30 Francis Okediji
                  Street, Old Bodija, Ibadan, Oyo State.
                </p>
                <p className='lh-lg'>
                  Also site offices are opened on temporary basis for effective
                  job execution.
                </p>

                <div className='second'>
                  <div className='py-1'></div>
                </div>
              </div>
              {/* <div className='col-lg-3 cert p-0 dot'>
                <img src={certificate} alt='' width='100%' className='image dots cef' />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Certificate
