import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import logo from '../../../images/Logo.svg'
import Navbar from '../../Navbar/Navbar';

import "./ProjectMore.css"

const ProjectMore = (props) => {
   const location = useLocation();
  console.log(location.state.aim);

// https://stackoverflow.com/questions/52238637/react-router-how-to-pass-data-between-pages-in-react

  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [location]);
    // console.log(value);
  return (
    <section>
      <Navbar logo={logo} btncolor="#ffffff" btncolors="#3366B0"/>
      <div className='parent-projectmore'>
        <div className='sub-projectmore'>
          <div className='more-title w-100'>
            <h1 className='h1-responsive text-start my-4 '>
              <b>Our Projects</b>
            </h1>
          </div>
          <div className='proj1 my-4'>
            <img src={location.state.aim.im} alt='' />
            <h1>{location.state.aim.num}</h1>
          </div>
          <div className='proj2'>
            <div className='bot'></div>
            <h4 className='p-0 m-0'>{location.state.aim.word}</h4>
          </div>
          <div className='proj3 my-5'>
            <p>
              <b className=''>Employer Name: </b>FEDERAL MINISTRY OF
              AGRICULTURE, ABUJA, FCT.
            </p>
            <p>
              <b className='mr-1'>Location: </b> Markurdi, Benue State.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProjectMore
