import React from 'react'
import './Home.css'
import imageofcaterpillar from '../../../images/imageofcaterpillar.png'
import curve from '../../../images/curve.svg'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <>
      {/* the destop */}
      <section id='move'>
        <div className='parent-home'>
          <div className='sub-home'>
            <div className='home1'>
              <div className='sub_home1'>
                <h1
                  className='h1-responsive text-bold lh-sm'
                  style={{ fontSize: '35px' }}
                >
                  <b>Quality. Safety. Durability. Cost Effectiveness.</b>
                </h1>
                <p className='lh-lg'>
                  Emniks Engineering Nigeria Limited is an engineering company
                  with desire and passion for excellence. Our commitment and
                  genuine concern for our clients in terms of meeting their
                  demands gives us an edge over others.
                </p>
                <Link to='/company'>
                  <button type='submit' className='btn my-4 btcs'>
                    Learn more <i class='bi bi-arrow-right text-bold'></i>
                  </button>
                </Link>
              </div>
              <div className='sub_home2'>
                <div className='city'>
                  {' '}
                  <h6>Project and Estate Management</h6>
                </div>
                <div className='poll'>
                  {' '}
                  <h6>Engineering Constructions</h6>
                </div>
                <div className='house'>
                  <h6>Engineering Constructions</h6>
                </div>
              </div>
            </div>
            <div className='home2'>
              <div className='home-img'>
                <img src={curve} alt='' className='curve' />
                <img src={imageofcaterpillar} alt='' className='norm' />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* the mobile */}
      <section>
        <div className='parent-home1 hide'>
          <div className='sub-home1'>
            <div className='home11'>
              <div className='sub_home11'>
                <h1
                  className='h1-responsive text-bold lh-sm'
                  style={{ fontSize: '24px' }}
                >
                  <b>Quality. Safety. Durability. Cost Effectiveness.</b>
                </h1>
                <p className='lh-lg'>
                  Emniks Engineering Nigeria Limited is an engineering company
                  with desire and passion for excellence. Our commitment and
                  genuine concern for our clients in terms of meeting their
                  demands gives us an edge over others.
                </p>
                <button type='submit' className='btn my-4 btc'>
                  Learn more <i class='bi bi-arrow-right text-bold'></i>
                </button>
              </div>
              <div className='sub_home21'>
                <div className='side1'>
                  <div className='city'>
                    {' '}
                    <h4>Project and Estate Management</h4>
                  </div>
                  <div className='poll'>
                    {' '}
                    <h4>Engineering Constructions</h4>
                  </div>
                  <div className='house'>
                    <h4>Structural Designs</h4>
                  </div>
                </div>
                {/* <div className='home2'>
                  <div className='home-img'>
                    <img src={curve} alt='' className='curve' />
                    <img src={imageofcaterpillar} alt='' className='norm' />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Home
