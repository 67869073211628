import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import logo from '../../images/Logo.svg'
import Navbar from '../Navbar/Navbar'
import Discuss from './Discuss/Discuss'
import ShowProject from './ShowProject/ShowProject'

const Projects = () => {
  const routepath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])
  return (
    <div>
      <Navbar logo={logo} btncolor='#ffffff' btncolors='#3366B0' />
      <ShowProject />
      <Discuss />
    </div>
  )
}

export default Projects