import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import Company from './Components/Company/Company'
import Contact from './Components/Contact/Contact'
import Footer from './Components/Footer/Footer'
import Gallery from './Components/Gallery/Gallery'
import Homes from './Components/Homes/Homes'
import PageNotFound from './Components/PageNotFound/PageNotFound'
import ProjectMore from './Components/Projects/ProjectMore/ProjectMore'
import Projects from './Components/Projects/Projects'
import Services from './Components/Services/Services'

function App() {
  return (
    <div className='App'>
      <>
        <Router>
          <Routes>
            <Route path='/' exact element={<Homes />} />
            <Route path='/company' element={<Company />} />
            <Route path='/projects' element={<Projects />} />
            <Route path='/projects/:num' element={<ProjectMore />} />
            <Route path='/gallery' element={<Gallery />} />
            <Route path='/services' element={<Services />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/*' element={<PageNotFound />} />
          </Routes>
          <Footer />
        </Router>
      </>
    </div>
  )
}

export default App
