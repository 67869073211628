import React from 'react'
import { Link } from 'react-router-dom'

import "./Discuss.css"

const Discuss = () => {
  return (
    <section>
      <div className='parent-discuss'>
        <div className='sub-discuss'>
          <div className='row'>
            <div className='col'>
              <h1 className='h1-responsive'>
                <b>Let’s Discuss your Project</b>
              </h1>
              <p>
                Contact us now and let’s discuss your next project and be
                assured of getting quality and excellent service delivery.
              </p>
              <Link to='/contact-us'>
                <button className='btn shadow-none btc'>Contact us</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Discuss
