import React from 'react'
import card1 from '../../../icons/card1.png'
import card2 from '../../../icons/card2.png'
import card3 from '../../../icons/card3.png'
import card4 from '../../../icons/card4.png'
import card5 from '../../../icons/card5.png'
import card6 from '../../../icons/card6.png'
import card7 from '../../../icons/card7.png'
import card8 from '../../../icons/card8.png'
 

const Mock = () => {
  const aims = [
    {
      i: 1,
      im: card1,
      content: 'Design and Construction of highways.',
    },
    {
      i: 2,
      im: card2,
      content: 'Design and Construction of Municipal and Community roads.',
    },
    {
      i: 3,
      im: card3,
      content: 'Design and Construction of bridges and interchange.',
    },
    {
      i: 4,
      im: card4,
      content:
        'Design and Construction of Building Structures including High-rise Buildings. ',
    },
    {
      i: 5,
      im: card5,
      content: 'Design and Construction of water treatment plants.',
    },
    {
      i: 6,
      im: card6,
      content:
        'Design and Construction of Waste Dumps, Sewage treatment and disposal units.',
    },
    {
      i: 7,
      im: card7,
      content: 'Design and Construction of Irrigation systems.',
    },
    {
      i: 8,
      im: card8,
      content:
        'Design and Construction of Gas/Petrol Filling Stations, Telecommunication mast and other relevant Civil engineering Projects.',
    },
  ]

  return (
    <section>
      <div className='parent-more p-0 my-5 '>
        <div className='sub-more'>
          <div className='more-title mt-5'>
            <h1 className='h1-responsive text-center mb-4 '>Other Services</h1>
            <p className='text-center w-responsive mx-auto mb-5'>
              We provide comprehensive Engineering services in the following
              areas of specialization.
            </p>
          </div>
          <div className='row justify-content-center p-0 m-0'>
            {aims.map((aim, i) => {
              const { im, content } = aim
              return (
                <div className='col-md-6 mb-4 col-lg-6' key={i}>
                  <div className='card shadow h-100 first py-5 px-3 br-md cards d-flex justify-content-center align-items-center flex-row'>
                    <div className='mb-3 mx-2 card-icon'>
                      <img src={im} alt='' />
                    </div>
                    {/* <h5 className=" my-4">
                                Humanity and Human Rights Alertness
                            </h5> */}
                    <p className='text-start mx-3 text-capitalize'>{content}</p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Mock
