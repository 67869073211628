import React from 'react'
import "./ShowProject.css"

import card1 from '../../../images/profile.png'
import { useNavigate } from 'react-router-dom'


const ShowProject = (props) => {

    const navigate = useNavigate()

    const ProjectMore = (aim) =>{
        navigate(`/projects/${aim.num}`, { state: { aim}})
    }

    const aims = [
      {
        num: "01",
        im: card1,
        move: 'Visit Project',
        word: 'Renovation of Store Building, Green House & Construction of Drainage Channel ',
      },
      {
        num: "02",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of a 2000 Capacity Event Centre  ',
      },
      {
        num: "03",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of blocks of 6 Classroom with borehole at Lekki Town, Lekki free Zone, ',
      },
      {
        num: "04",
        im: card1,
        move: 'Visit Project',
        word: 'Re-modification of blocks of building to Office Complex ',
      },
      {
        num: "05",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of a set of classroom at ldi-Agbaro Ologuneru-Eruwa Road, Ibadan ',
      },
      {
        num: "06",
        im: card1,
        move: 'Visit Project',
        word: 'Design and Supervision of Central Faculty of Science,',
      },
      {
        num: "07",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of Cafeteria Complex ',
      },
      {
        num: "08",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of 5 block of 6in1 3bedroom Apartments at Ahoyaya via Ibeju Road, Lagos ',
      },
      {
        num: "09",
        im: card1,
        move: 'Visit Project',
        word: 'Engineering Design and Supervision of Aworogbagba Road Yewa North, Ogun State ',
      },
      {
        num: "10",
        im: card1,
        move: 'Visit Project',
        word: 'Engineering Design and rehabilitation of Arowojobe Road, Oshodi',
      },
      {
        num: "11",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of Residential Building ',
      },
      {
        num: "12",
        im: card1,
        move: 'Visit Project',
        word: 'Rehabilitation of Junior Officers Mess, Odogbo Army Barracks, Ibadan',
      },
      {
        num: "13",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of Christ Chapel UCH, Phase IV ',
      },
      {
        num: "14",
        im: card1,
        move: 'Visit Project',
        word: 'Construction of Christ Chapel UCH, Phase Ill',
      },
    ]

  return (
    <section>
      <div className='container-fluid d-flex justify-content-center align-items-center m-0 p-0'>
        <div className='showproject'>
          <div className='more-title'>
            <h1 className='h1-responsive text-center my-5 '>Our Projects</h1>
          </div>
          <div className='row justify-content-between align-items-center mx-0'>
            {aims.map((aim, i) => {
              const { num, im, move, word } = aim
              return (
                <div className='mb-4 col-lg-6 p-0' key={i}>
                  <div className='card shadow h-100 first py-4 px-3 br-md cards '>
                    <div className='d-flex justify-content-start align-items-start '>
                      <h1 className='cite mx-3'>{num}</h1>
                      <p className='px-4'>{word}</p>
                    </div>
                    <div className='mb-3'>
                      <img
                        src={im}
                        alt=''
                        width={'100%'}
                      />
                    </div>
                    {/* <h5 className=" my-4">
                                Humanity and Human Rights Alertness
                            </h5> */}
                    <p
                      className=' p-0 point'
                      onClick={() => {
                        ProjectMore(aim)
                      }}
                    >
                      {move} <i class='bi bi-arrow-right fa-2x'></i>
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShowProject