import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Testimonies from '../Homes/Testimonies/Testimonies'
import Mock from './Mock/Mock'
import Service from './Service/Service'


import logo from '../../images/Logo.svg'
import Navbar from '../Navbar/Navbar'

const Services = () => {
  const routepath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])
  return (
    <div>
      <Navbar logo={logo} btncolor='#ffffff' btncolors='#3366B0' />
      <Service />
      <Testimonies />
      <Mock />
    </div>
  )
}

export default Services
