import React from 'react'
import vision from '../../../images/vision.png'
import mission from '../../../images/mission.png'
import core from '../../../images/core.png'
import "./Vision.css"

const Vision = () => {
  return (
    <section>
      <div className='parent-vision'>
        <div className='sub-vision'>
          <div className='vision1'>
            <div className='vision-title'>
              <h1 className='h1-responsive'>
                <b>About Us</b>
              </h1>
            </div>
            <div className='vision-card'>
              <div className='vision'>
                <h3>Our Vision</h3>
                <p>
                  To be a world-class project and construction manager, with the
                  ability to provide the best and cost effective services/
                  delivery to our valued clients.{' '}
                </p>
              </div>
              <div className='mission'>
                <h3>Our Mission Statement</h3>
                <p>
                  To undertake projects keeping in mind quality, aesthetic,
                  durability and excellence at a reasonable cost.
                </p>
              </div>
              <div className='core'>
                <h3>Our Core Values</h3>
                <ul>
                  <li>Creativity</li>
                  <li>Integrity</li>
                  <li> High professionalism and technical competence</li>
                  <li>Timely and Excellence service delivery</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='vision2'>
            <img src={vision} alt=''  className='vision1'/>
            <img src={mission} alt='' className="mission1"/>
            <img src={core} alt='' className='core1'/>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Vision
