import React from 'react'

import './Testimonies.css'

import Carousel from 'react-bootstrap/Carousel'

const Testimonies = () => {
  return (
    <section>
      <div className='parent-testimonies my-5'>
        <div className='sub-testimonies'>
          <Carousel
            fade
            indicators={false}
            variant='dark'
            interval={10000}
            role='listbox'
          >
            <Carousel.Item>
              <div className='row m-0 p-0 w-100 d-flex justify-content-center align-items-center'>
                <div className='col-lg-8'>
                  <div className='card shadow h-100 first py-4 px-3 br-md d-flex align-items-start'>
                    <h1 className='yellow quote'>“</h1>
                    <p>
                      We recommend that Emniks Engineering and its Management
                      are reliable, good and trustworthy. They have worked with
                      us on two occasIons, they were found to be honest, they
                      did not compromise quality in any form. We therefore
                      recommend them for any type of project(s) you may want to
                      offer them.
                    </p>
                    <h5 className='text-end w-100'>
                      -MAO & ASSOCIATES (NIG) LTD
                    </h5>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row m-0 p-0 d-flex justify-content-center align-items-center'>
                <div className='col-lg-8'>
                  <div className='card shadow h-100 first py-4 px-3 br-md d-flex align-items-start'>
                    <h1 className='yellow quote'>“</h1>
                    <p>
                      This is to humbly inform you that EMNIKS ENGINEERING NIG.
                      LTD is one of the Local Government reputable contractors
                      and in view of, their competency in handling the jobs
                      given to them revealed high level of professionalism in
                      the field on construction of any type. The Local
                      Government is hereby recommending this company to you to
                      avail them the opportunity to handle construction of any
                      type or you. Grateful for taking us for our words.
                    </p>
                    <h5 className='text-end w-100'>
                      -IBEJU-LEKKI LOCAL GOVERNMENT
                    </h5>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row m-0 p-0 d-flex justify-content-center align-items-center'>
                <div className='col-lg-8'>
                  <div className='card shadow h-100 first py-4 px-3 br-md d-flex align-items-start'>
                    <h1 className='yellow quote'>“</h1>
                    <p>
                      We recommend that Emniks Engineering and its Management
                      are reliable, good and trustworthy. They have worked with
                      us on two occasIons, they were found to be honest, they
                      did not compromise quality in any form. We therefore
                      recommend them for any type of project(s) you may want to
                      offer them.
                    </p>
                    <h5 className='text-end w-100'>
                      -MAO & ASSOCIATES (NI) LTD
                    </h5>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className='row m-0 p-0 d-flex justify-content-center align-items-center'>
                <div className='col-lg-8 m-0'>
                  <div className='card shadow h-100 first py-4 px-3 br-md d-flex align-items-start'>
                    <div className='yellow quote'><Quo /></div>
                    <p>
                      Emniks Engineering Limited is out to meet the need in the
                      Engineering/Construction field. They are well equipped for
                      excellent service delivery
                    </p>
                    <h5 className='text-end w-100'>
                      -Managing Director <br /> Shepherds Associates. Quantity
                      Surveyors
                    </h5>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </section>
  )
}

export default Testimonies

function Quo(){
  return (
    <svg
      width='46'
      height='28'
      viewBox='0 0 46 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.69 27.94H0.41L12.29 0.219998H22.96L16.69 27.94ZM38.91 27.94H22.63L34.51 0.219998H45.18L38.91 27.94Z'
        fill='#3366B0'
      />
    </svg>
  )
}