import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Vision from '../Homes/Vision/Vision'
import Board from './Board/Board'
import Certificate from './Certificate/Certificate'
import Details from './Details/Details'
import Information from './Information/Information'

import logo from '../../images/Logo.svg'
import Navbar from '../Navbar/Navbar'
const Company = () => {
  const routepath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])
  return (
    <div>
      <Navbar logo={logo} btncolor='#ffffff' btncolors='#3366B0' />
      <Information />
      <Details />
      <Vision />
      <Certificate />
      <Board />
    </div>
  )
}

export default Company
