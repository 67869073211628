import React from 'react'
import "./Information.css"

const Information = () => {
  return (
    <section>
      <div className='parent-information'>
        <div className='sub-information'>
          <div className='information-title text-center pt-5 w-100'>
            <h1 className='h1-responsive text-center '>
              <b>About Our Company</b>
            </h1>
          </div>
          <div className='information-title text-start pt-5 w-100'>
            <h4 className='h1-responsive text-lg-start text-center yellow'>
              Company Information
            </h4>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0  h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Country of Incorporation:</b>
              </h6>
              <p className='px-2 m-0'>Emniks Engineering Limited</p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0  h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Country of Incorporation:</b>
              </h6>
              <p className='px-2 m-0'>Nigeria</p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0  h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Incorporation Number:</b>
              </h6>
              <p className='px-2 m-0'>RC 775366 </p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0  h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Year of Incorporation:</b>
              </h6>
              <p className='px-2 m-0'>2008 </p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0 h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Company Registered Office:</b>
              </h6>
              <p className='px-2 m-0'>
                No 30, Francis Okediji Street, Bodija, Ibadan
              </p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0 h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Telephone:</b>
              </h6>
              <p className='px-2 m-0'>08033536339, 08038344200 </p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0 h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Email:</b>
              </h6>
              <p className='px-2 m-0'>emniks@yahoo.com </p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
          <div className='row first align-items-start justify-content-start py-4 px-0 mx-0 w-100'>
            <div className='col-md-12 d-flex align-items-center justify-content-start flex-row m-0 px-0  h-100 w-100'>
              <div className='boxs'></div>
              <h6 className='m-0 px-3'>
                <b>Email:</b>
              </h6>
              <p className='px-2 m-0'>emniks@yahoo.com </p>
              {/* <div className='py-1 pry br-md mt-5'></div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Information
