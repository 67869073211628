import React, { useEffect, useState } from 'react'
import axios from 'axios'
import './Contact.css'
import { useLocation } from 'react-router-dom'


import logo from '../../images/Logo.svg'
import Navbar from '../Navbar/Navbar'
const Contact = () => {
  // --------- States

  const routepath = useLocation()
  const onTop = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    onTop()
  }, [routepath])

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [sucess, setSucess] = useState('')
  const [loading, setLoading] = useState('Send Message')
  const [error, setError] = useState('')
  
      const [timeOut, setTimeOut] = useState(false)

  // -------------  - Submitting Form Data

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading('Loading...')
    let myForm = new FormData()
    myForm.append('name', name)
    myForm.append('email', email)
    myForm.append('message', message)



    setTimeout(() => {
      setTimeOut(false)
    }, 8000)


    axios
      .post('https://formspree.io/f/xzbwklaq', myForm, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })

      .then((response) => {
        setLoading('Send Message')
        setTimeOut(true)
        setSucess('Your message has been sent!')
        setName('')
        setMessage('')
        setEmail('')
      }).catch((error) => {
        setError('There was a problem sending your message!')
      })
  }

  return (
    <>
      <Navbar logo={logo} btncolor='#ffffff' btncolors='#3366B0' />
      <section className='message' id='cop'>
        <div className='pas'>
          <h1 data-aos='flip-right'>Contact Us</h1>
          <form onSubmit={handleSubmit}>
            <div className='name'>
              <input
                type='text'
                name='sendername'
                onChange={(e) => setName(e.target.value)}
                value={name}
                id='na'
                placeholder='Can we know your Full Name...'
                required
              />
            </div>
            <div className='name'>
              <input
                type='email'
                name='mailFrom'
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                id='na'
                placeholder='Your email address*'
                required
              />
            </div>
            <div className='write'>
              <textarea
                name=''
                id='writ'
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                cols='30'
                rows='10'
                placeholder='Write your message to us here...'
                required
              ></textarea>
            </div>
            {timeOut ? (
              <span className='text-center mb-2'>
                {sucess} {error}
              </span>
            ) : (
              <></>
            )}
            <div className='mid mt-5'>
              <button type='submit' className='contactus'>
                {loading}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default Contact
