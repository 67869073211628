import React from 'react'

import "./Details.css"

const Details = () => {
  return (
    <section>
      <div className='parent-service'>
        <div className='sub-service'>
          <div className='my-2 text-start w-100'>
            <div className='text-start mb-2 pry-bold-text'>
              <h1 class='h1-responsive text-start my-2 yellow'>The Company</h1>
            </div>
          </div>
          <div className=''>
            <div className='row first align-items-start justify-content-between py-4 px-0 w-100 m-0'>
              <div className='col-lg-5 px-0'>
                <p className='pb-3 lh-lg px-0'>
                  Emniks Engineering Limited is known for Structural designs,
                  Engineering Constructions, Project and Estate Management. It
                  is an Engineering company with a desire and passion for
                  excellence. The company was established mainly for "excellence
                  service delivery and cost effectiveness" in great and high
                  quality projects which are achieved not only by its aesthetic
                  value but by great minds, who can incorporate safety,
                  durability, quality and cost. We are team of well equipped
                  professional Engineers armed with latest and modern technology
                  to meet the demand of the construction industry.
                </p>
                {/* <div className='py-1 pry br-md mt-5'></div> */}
              </div>
              <div className='col-lg-4 text-center leaps'>
                <div className='citys mb-2 w-100'>
                  <h6>Project and Estate Management</h6>
                </div>
                <div className='polls mb-2 w-100'>
                  <h6>Engineering Constructions</h6>
                </div>
                <div className='houses mb-2 w-100'>
                  <h6>Engineering Constructions</h6>
                </div>
              </div>
            </div>
            <div className='row first align-items-center justify-content-center py-2 mx-0 px-0 w-100'>
              <div className='col-md-12 px-0 mx-0 line'>
                <p className='py-4 lh-lg'>
                  The firm success stands on the foundation of training, good
                  human relationship and regular upgrading of our staff and
                  equipment. Also, our commitment and genuine concern for our
                  clients in terms of meeting their demands gives us an edge
                  over others.
                </p>
              </div>
            </div>
            <div className='row first align-items-center justify-content-center p-0 mx-0 w-100'>
              <div className='col-md-12 line px-0 mx-0'>
                <p className='pb-2 lh-lg'>
                  The team consists of brilliant Architects, Professional
                  Civil/Structural Engineers, Quantity Surveyors, and
                  Electrical/Mechanical Engineers to meet all requirements in
                  the Building/Civil Engineering Industry.
                </p>
                <div className='py-1 pry br-md mt-5'></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Details
