import React from 'react'

import "./Footer.css"

const Footer = () => {
      const date = new Date().getFullYear()

  return (
    <div>
      <footer className='text-center text-lg-start d-flex justify-content-center align-items-center flex-column p-0 m-0 w-100'>
        <section className='light-text d-flex justify-content-center m-0 p-0  bount'>
          <div className='container-fluid text-center text-md-start mt-2 p-0 mx-0  p-0'>
            <div className='row pt-5 m-0 justify-content-center w-100'>
              <div className='col-md-3 col-lg-4 col-xl-3 mx-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 sec-bold-text'>
                  Address
                </h6>
                <p>
                  Head Office: No 30, Francis Okediji Street, Bodija, Ibadan
                </p>
                <p>Lagos Office: 30A Ladoke Akintola Str. Ikeja GRA, Lagos.</p>
              </div>

              <div className='col-md-3 col-lg-2 col-xl-2 mx-auto mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 sec-bold-text'>
                  Email Address
                </h6>
                <p>emniks@yahoo.com</p>
              </div>

              <div className='col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4'>
                <h6 className='text-uppercase fw-bold mb-4 sec-bold-text'>
                  Phone Number
                </h6>
                <p>08033536339, 08038344200</p>
              </div>
            </div>
          </div>
        </section>
        <hr
          width={'90%'}
          className='d-flex justify-content-center align-items-center'
        />
        <div
          className='text-center p-4 light-text'
          style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
        >
          &copy; <span>{date}</span> <span>Emniks Engineering LTD.</span> All
          Rights Reserved
        </div>
      </footer>
    </div>
  )
}

export default Footer
